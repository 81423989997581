exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-totalwar-rome-2-js": () => import("./../../../src/pages/totalwar/rome2.js" /* webpackChunkName: "component---src-pages-totalwar-rome-2-js" */),
  "component---src-pages-totalwar-troy-factions-ithaca-js": () => import("./../../../src/pages/totalwar/troy/factions/ithaca.js" /* webpackChunkName: "component---src-pages-totalwar-troy-factions-ithaca-js" */),
  "component---src-pages-totalwar-troy-factions-sparta-js": () => import("./../../../src/pages/totalwar/troy/factions/sparta.js" /* webpackChunkName: "component---src-pages-totalwar-troy-factions-sparta-js" */),
  "component---src-pages-totalwar-troy-js": () => import("./../../../src/pages/totalwar/troy.js" /* webpackChunkName: "component---src-pages-totalwar-troy-js" */),
  "component---src-pages-totalwar-troy-techs-js": () => import("./../../../src/pages/totalwar/troy/techs.js" /* webpackChunkName: "component---src-pages-totalwar-troy-techs-js" */),
  "component---src-pages-totalwar-troy-units-js": () => import("./../../../src/pages/totalwar/troy/units.js" /* webpackChunkName: "component---src-pages-totalwar-troy-units-js" */)
}

